import request from "@/utils/request"

// 用户列表
export function serverApiUserPage(data) {
  return request({
    url: "/serverApiUser/serverApiUserPage",
    method: "post",
    data
  })
}

//用户-审核列表
export function serverApiUserAuditPage(data) {
  return request({
    url: "/serverApiUser/serverApiUserAuditPage",
    method: "post",
    data
  })
}


//用户审核通过
export function applyPass(data) {
  return request({
    url: "/serverApiUser/applyPass",
    method: "get",
    params: data
  })
}

//用户审核拒绝
export function applyNoPass(data) {
  return request({
    url: "/serverApiUser/applyNoPass",
    method: "get",
    params: data
  })
}
//用户登录短信模板
export function userLoginPushMessage(data) {
  return request({
    url: "/serverApiUser/userLoginPushMessage",
    method: 'post',
    data
  })
}

//模糊查询企业
export function selectByCompanyFullName(query) {
  return request({
    url: "/serverCompany/selectByCompanyFullName",
    method: "get",
    params: query
  })
}
//小程序用户导出
export function userDataExport(data) {
  return request({
    url: "/serverApiUser/userDataExport",
    method: 'post',
    responseType: "blob",
    data
  })
}
//用户审核拒绝(智选)
export function smartApplyNoPass(query) {
  return request({
    url: "/serverApiUser/smartApplyNoPass",
    method: "get",
    params: query
  })
}
//用户审核通过(智选)
export function smartApplyPass(query) {
  return request({
    url: "/serverApiUser/smartApplyPass",
    method: "get",
    params: query
  })
}


